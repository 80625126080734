<template>
  <main class="form-signin text-center">
    <form>
      <img
        class="mb-4"
        src="@/assets/logo.png"
        alt="logo"
        width="96" height="96"
      >
      <label
        for="inputEmail"
        class="visually-hidden"
      >
      Username
      </label>
      <input
        type="email"
        id="inputEmail"
        class="form-control"
        placeholder="Username"
        v-model="username"
        autocomplete="username"
        autofocus
      >
      <label for="inputPassword" class="visually-hidden">
        Password
      </label>
      <input
        type="password"
        id="inputPassword"
        class="form-control"
        placeholder="Password"
        v-model="password"
        autocomplete="current-password"
      >
      <button
        class="w-100 btn btn-primary"
        type="submit"
        @click="signIn"
      >
        Sign in
      </button>
      <div class="mt-3"><a href="#">Forgot your password?</a></div>
      </form>
  </main>
</template>

<script>
  import { defineComponent } from 'vue'
  import { auth } from '@/helpers/auth'

  export default defineComponent({
    name: 'Auth',
    setup() {
      const { username, password, signIn } = auth()
      return { username, password, signIn }
    },
  })
</script>

<style scoped>
  .form-signin {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;
    padding-top: 100px;
  }

  .form-signin .checkbox {
    font-weight: 400;
  }

  .form-signin .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
  }

  .form-signin .form-control:focus {
    z-index: 2;
  }

  .form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
</style>